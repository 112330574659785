import { makeStyles } from '@material-ui/core/styles'
import { COLORS, FONTS, BREAKPOINTS, WIDTH_DIMENSIONS } from './../constants'

export const styles = makeStyles(theme => ({
  desktop: {
    display: 'block',
    [`@media (max-width: ${BREAKPOINTS.md}px)`]: {
      display: 'none',
    },
  },
  mobile: {
    display: 'none',
    [`@media (max-width: ${BREAKPOINTS.md}px)`]: {
      display: 'block',
      lineHeight: 1.2,
      fontSize: '1.875rem',
    },
  },
  root: {
    minHeight: '100vh',
  },
  searchFormContainer: {
    margin: '0 auto',
    marginTop: 10,
    maxWidth: WIDTH_DIMENSIONS.max,
    top: 0,
    background: COLORS.white,
    zIndex: 10,
    paddingBottom: 50,
    fontFamily: FONTS.Graphik.GraphikRegular,
    [`@media (min-width: ${BREAKPOINTS.lg}px)`]: {
      marginTop: 50,
      marginBottom: 50,
    },
  },
  searchForm: {
    maxWidth: 1190,
    width: '90%',
    margin: 'auto',
  },
  inputfield: {
    fontFamily: `${FONTS.Schnyder.SchnyderXLLight} !important`,
    fontSize: '1.875rem !important',
    [`@media (min-width: ${BREAKPOINTS.md}px)`]: {
      fontSize: '3.75rem !important',
    },
  },
  keywordsContainer: {
    background: COLORS.whiteStain,
  },
  keywordsList: {
    maxWidth: 343,
    listStyleType: 'none',
    padding: 20,
    margin: 0,
  },
  keywordItem: {
    cursor: 'pointer',
  },
  searchResults: {
    fontFamily: FONTS.Graphik.GraphikLight,
    width: '100%',
    margin: '0 auto',
    overflow: 'hidden',
    maxWidth: WIDTH_DIMENSIONS.max,
    [`@media (min-width: ${BREAKPOINTS.lg}px)`]: {
      width: '90%',
    },
  },
  // TODO: Remove the below styles when using the Card Component
  trendingContainer: {
    width: '90%',
    margin: '0 auto',
  },
  container: {
    width: '100%',
    maxWidth: 343,
  },
  imageContainer: {
    position: 'relative',
  },
  image: {
    width: '100%',
    maxWidth: 343,
    height: 454,
    objectFit: 'cover',
  },
  heart: {
    width: 40,
    height: 40,
    position: 'absolute',
    right: '1rem',
    top: '1rem',
    borderRadius: '50%',
    backgroundColor: '#FAFBFD',
    textAlign: 'center',
    padding: 6,
  },
  productInfo: {
    fontFamily: 'Graphik Regular',
    fontSize: '0.75rem',
  },
  cashBack: {
    fontFamily: 'Graphik Regular',
    fontSize: '0.75rem',
    position: 'absolute',
    bottom: 0,
    backgroundColor: 'rgb(250,251,253)',
    opacity: 0.8,
    width: '100%',
    // maxWidth: 343,
    textAlign: 'center',
    paddingTop: '0.75rem',
    paddingBottom: '0.75rem',
    marginBottom: 0,
  },
  productName: {},
  price: {},
  nameAndPrice: {
    display: 'flex',
    justifyContent: 'space-between',
    paddingTop: 20,
  },
  retailerName: {
    fontSize: '0.75rem',
  },
  paginationContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: 30,
  },
}))
