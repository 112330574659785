import React, { useState, useEffect, useCallback, useRef } from 'react'
import { TFunction } from 'i18next'
import { withTranslation } from 'react-i18next'
import Layout from '../layouts/defaultLayout'
import SEO from '../components/seo'
import { styles } from '../pagesStyles/searchStyles'
import { Grid, Typography } from '@material-ui/core'
import { TYPOGRAPHY_ALIGNMENT, TYPOGRAPHY_SIZES } from './../constants'
import debounce from 'lodash.debounce'
import CHRText from '../components/typography'
// import reactStringReplace from 'react-string-replace'
import { useTheme } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import axios from '../axios'
import Card from '../components/trendingCard'
import CHRArrowButton from '../components/arrowButton'
import { useLocation } from '@reach/router'
import { parse } from 'query-string'
import { useAppState } from '../appState'
import Spinner from '../components/spinner'
import ClearIcon from '@material-ui/icons/Clear'
import TextField from '@material-ui/core/TextField'
import { IconButton } from '@material-ui/core'
import NotFound from './404'

interface SearchPageProps {
  t: TFunction
}

const PAGE_SIZE = 100

const SearchPage = ({ t, ...otherProps }: SearchPageProps) => {
  const { search } = useLocation()
  const { q, page } = parse(search)

  const [appState] = useAppState()
  const [query, setQuery] = useState(q || '')
  const [pageNumber, setPageNumber] = useState(Number(page) || 1)
  const [searchResultsCount, setSearchResultsCount] = useState(null)
  const [searchResults, setSearchResults] = useState([])
  const [loadingProducts, setLoadingProducts] = useState(false)
  // const [suggestedItems, setSuggestedItems] = useState([])
  // const [keywordSuggestions, setKeywordSuggestions] = useState([])
  const [error, setError] = useState('')

  const searchInputRef = useRef()

  const theme = useTheme()
  const isTabletAndAbove = useMediaQuery(theme.breakpoints.up('md'))

  const classes = styles()

  const updateProducts = (productId: string, shoppingBoardId: number) => {
    setSearchResults(items => {
      const i = items.findIndex(e => e.product_id === productId)
      return [
        ...items.slice(0, i),
        {
          ...items[i],
          shoppingBoardId,
        },
        ...items.slice(i + 1),
      ]
    })
  }

  const removeProduct = (productId: string) => {
    setSearchResults(items => {
      return items.filter(e => e.product_id !== productId)
    })
  }

  const fetchResults = async (q: string) => {
    try {
      if (q) {
        setLoadingProducts(true)
        const res = await axios.get(
          `/api/v1/search?q=${q}&size=${PAGE_SIZE}&offset=${PAGE_SIZE *
            (pageNumber - 1)}`
        )
        setSearchResultsCount(res.data.data.total)
        setSearchResults(res.data.data.results)
        setPageNumber(1)
        setLoadingProducts(false)
        // setSuggestedItems([])
        // setKeywordSuggestions(['Test develop test', 'TEST'])
      } else {
        setSearchResultsCount(null)
        setPageNumber(1)
      }
    } catch (err) {
      setError(t('messages.somethingWentWrong'))
    }
  }

  const debouncedSave = useCallback(
    debounce((q: string) => fetchResults(q), 300),
    []
  )

  const updateValue = (q: string) => {
    setQuery(q)
    window.history.pushState('', '', `?q=${q}`)
    debouncedSave(q)
  }

  const fetchNextPage = async () => {
    try {
      window.scroll({
        top: 200,
        behavior: 'auto',
      })
      setLoadingProducts(true)
      const offset = PAGE_SIZE * pageNumber
      const res = await axios.get(
        `/api/v1/search?q=${query}&size=${PAGE_SIZE}&offset=${offset}`
      )
      setSearchResultsCount(res.data.data.total)
      setSearchResults(res.data.data.results)
      window.history.pushState('', '', `?q=${query}&page=${pageNumber + 1}`)
      setPageNumber((p: number) => p + 1)
      setLoadingProducts(false)
    } catch (err) {
      setError(t('messages.somethingWentWrong'))
    }
  }

  const fetchPreviousPage = async () => {
    try {
      window.scroll({
        top: 200,
        behavior: 'auto',
      })
      setLoadingProducts(true)
      const offset = PAGE_SIZE * (pageNumber - 2)
      const res = await axios.get(`/api/v1/search?q=${query}&offset=${offset}`)
      setSearchResultsCount(res.data.data.total)
      setSearchResults(res.data.data.results)
      window.history.pushState('', '', `?q=${query}&page=${pageNumber - 1}`)
      setPageNumber((p: number) => p - 1)
      setLoadingProducts(false)
    } catch (err) {
      setError(t('messages.somethingWentWrong'))
    }
  }

  useEffect(() => {
    searchInputRef.current.focus()
    ;(async () => {
      try {
        if (query) {
          setLoadingProducts(true)
          const res = await axios.get(
            `/api/v1/search?q=${query}&size=${PAGE_SIZE}&offset=${PAGE_SIZE *
              (pageNumber - 1)}`
          )
          setSearchResultsCount(res.data.data.total)
          setSearchResults(res.data.data.results)
          setLoadingProducts(false)
          // setSuggestedItems([])
          // setKeywordSuggestions(['Test develop test', 'TEST'])
        } else {
          setSearchResultsCount(null)
          setPageNumber(1)
          window.history.pushState('', '', '?')
        }
      } catch (err) {
        setError(t('messages.somethingWentWrong'))
      }
    })()
  }, [])

  if (error) {
    return <NotFound error={error} />
  }

  return (
    <Layout>
      <SEO
        title={t('search.metaTitle')}
        description={t('company.metaDescription')}
      />
      <div className={classes.root}>
        <div className={classes.searchFormContainer}>
          <form
            onSubmit={e => {
              e.preventDefault()
            }}
            className={classes.searchForm}
            noValidate
            autoComplete="off"
          >
            <TextField
              type="text"
              placeholder={
                isTabletAndAbove
                  ? t('search.typeToSearchForAnything')
                  : t('search.typeToSearch')
              }
              fullWidth
              value={query}
              onChange={e => {
                updateValue(e.target.value)
              }}
              inputRef={searchInputRef}
              InputProps={{
                classes: {
                  input: classes.inputfield,
                },
                endAdornment: query && (
                  <IconButton onClick={() => updateValue('')}>
                    <ClearIcon color="primary" />
                  </IconButton>
                ),
              }}
            />
          </form>
        </div>
        <div>
          {searchResultsCount === 0 && (
            <div>
              <CHRText
                size={TYPOGRAPHY_SIZES.big}
                align={TYPOGRAPHY_ALIGNMENT.left}
                style={{
                  alignSelf: 'center',
                  width: '90%',
                  maxWidth: '1190px',
                  margin: 'auto',
                  marginBottom: '100px',
                }}
              >
                <span className={classes.desktop}>
                  {t('search.weCouldNotFind', {
                    searchQuery: query,
                  })}
                </span>
                <span className={classes.mobile}>
                  {t('search.weCouldNotFind', {
                    searchQuery: query,
                  })}
                </span>
              </CHRText>
              {/* <div className={classes.searchResults}>
                <Grid container alignItems="stretch" spacing={2}>
                  {suggestedItems.map(product => (
                    <Grid item lg={3} md={4} sm={4} xs={6}>
                      <Card productInfo={product} />
                    </Grid>
                  ))}
                </Grid>
              </div> */}
            </div>
          )}
          {searchResultsCount > 0 && (
            <>
              <div className={classes.searchResults}>
                <Grid container alignItems="stretch" spacing={2}>
                  {/* {keywordSuggestions.length > 0 && (
                  <Grid
                    item
                    lg={3}
                    md={4}
                    sm={4}
                    xs={6}
                    className={classes.keywordsContainer}
                  >
                    <ul className={classes.keywordsList}>
                      {keywordSuggestions.map((keyword: string) => (
                        <li
                          className={classes.keywordItem}
                          onClick={() => setQuery(keyword)}
                        >
                          {reactStringReplace(keyword, query, match => (
                            <b>{match}</b>
                          ))}
                        </li>
                      ))}
                    </ul>
                  </Grid>
                )} */}
                  {loadingProducts ? (
                    <Spinner />
                  ) : (
                    searchResults.map(product => (
                      <Grid
                        item
                        lg={3}
                        md={4}
                        sm={4}
                        xs={6}
                        key={product.product_id}
                      >
                        <Card
                          updateProducts={updateProducts}
                          onRemove={removeProduct}
                          userId={appState.userId}
                          productInfo={product}
                          shareable
                        />
                      </Grid>
                    ))
                  )}
                </Grid>
                <div className={classes.paginationContainer}>
                  <CHRArrowButton
                    isLeftArrow
                    label={t('shared.previous')}
                    onClick={fetchPreviousPage}
                    disabled={pageNumber === 1}
                  />
                  <Typography variant="subtitle2" component="p">
                    {pageNumber}/{Math.ceil(searchResultsCount / PAGE_SIZE)}
                  </Typography>
                  <CHRArrowButton
                    label={t('shared.next')}
                    onClick={fetchNextPage}
                    disabled={
                      pageNumber === Math.ceil(searchResultsCount / PAGE_SIZE)
                    }
                  />
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </Layout>
  )
}

export default withTranslation()(SearchPage)
